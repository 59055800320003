<!-- =========================================================================================
	File Name: ItemGridView.vue
	Description: Item Component - Grid VIew
========================================================================================== -->

<template>
  <vx-card class="grid-view-item mb-base overflow-hidden">
    <template slot="no-body">
      <!-- ITEM IMAGE -->
      <div class="item-img-container bg-white flex mb-4 mt-4">
        <div class="px-4 w-1/2">
          <img :src="item.img" :alt="item.name" class="grid-view-img" />
        </div>
        <div class="w-1/2 items-center">
          <slot name="action-buttons" />
        </div>
      </div>
      <div class="item-details px-4">
        <!-- TITLE & DESCRIPTION -->
        <div class="my-4">
          <h6 class="truncate font-semibold mb-1">{{ item.name }}</h6>
          <p class="item-description text-sm">{{ item.description.slice(0,300) }}</p>
        </div>
      </div>
      <!-- SLOT: ACTION BUTTONS -->
    </template>
  </vx-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-view-item {
  .grid-view-img {
    max-height: 68px;
    max-width: 190px;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>